import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactFrom from "../components/contactForm"

import $ from "jquery"
import ShapeContact from "../components/shapes/ShapeContact"

const ContactPage = () => {
  useEffect(() => {
    function randLetter() {
      var letters = ["a", "4"]
      var letter = letters[Math.floor(Math.random() * letters.length)]
      return letter
    }
    setInterval(function() {
      $(".page_header span").html(randLetter())
    }, 500)
  })

  return (
    <Layout>
      <SEO title="Kontakt" />
      <main className="contact_page">
        <ShapeContact />

        <div className="container-fluid">
          <div className="page_header_wrapper">
            <h1 className="page_header" data-text="&#60;Kont4kt/&#62;">
              &#60;Kont<span>4</span>kt/&#62;
            </h1>
          </div>

          <div className="inner">
            <div className="row faq_info align-items-center">
              <div className="col-sm-8">
                <div className="desc">
                  Chcesz dowiedzieć się jak możemy Ci pomóc? <br />
                  Skontaktuj się z nami i wspólnie postaramy się znaleźć
                  najlepsze rozwiązania dopasowane do twojego biznesu.
                </div>
              </div>
              <div className="col-sm-4 text-right">
                <Link to="/faq/" className="btn_custom">
                  &#60;FAQ/&#62;
                </Link>
              </div>
            </div>
            <ContactFrom />
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default ContactPage
